import React from "react";

import "react-toastify/dist/ReactToastify.css";
import "./src/styles/theme-sandbox/style.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./src/styles/datepicker.scss";
import "react-image-lightbox/style.css";
import "./src/styles/common.scss";
import "./src/styles/fonts/thicccboi/thicccboi.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { AuthContextProvider } from "./src/auth";
import { toast } from "react-toastify";

toast.configure({
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  theme: "colored",
});

export const wrapRootElement = ({ element }) => (
  <AuthContextProvider>{element}</AuthContextProvider>
);
