import { LOGIN, LOGOUT } from "./types";

export const initialState = {
  loggedIn: false,
  data: {},
  connections: [],
};

let reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, loggedIn: true, ...action.payload };
    case LOGOUT:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default reducer;
