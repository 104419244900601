exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-index-jsx": () => import("./../../../src/pages/about-us/index.jsx" /* webpackChunkName: "component---src-pages-about-us-index-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-faq-index-jsx": () => import("./../../../src/pages/faq/index.jsx" /* webpackChunkName: "component---src-pages-faq-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-industries-index-jsx": () => import("./../../../src/pages/industries/index.jsx" /* webpackChunkName: "component---src-pages-industries-index-jsx" */),
  "component---src-pages-news-index-jsx": () => import("./../../../src/pages/news/index.jsx" /* webpackChunkName: "component---src-pages-news-index-jsx" */),
  "component---src-pages-news-single-news-jsx": () => import("./../../../src/pages/news/_SingleNews.jsx" /* webpackChunkName: "component---src-pages-news-single-news-jsx" */),
  "component---src-pages-policies-privacy-policy-jsx": () => import("./../../../src/pages/policies/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-policies-privacy-policy-jsx" */),
  "component---src-pages-policies-refund-policy-jsx": () => import("./../../../src/pages/policies/refund-policy.jsx" /* webpackChunkName: "component---src-pages-policies-refund-policy-jsx" */),
  "component---src-pages-policies-t-c-jsx": () => import("./../../../src/pages/policies/t&c.jsx" /* webpackChunkName: "component---src-pages-policies-t-c-jsx" */),
  "component---src-pages-services-index-jsx": () => import("./../../../src/pages/services/index.jsx" /* webpackChunkName: "component---src-pages-services-index-jsx" */),
  "component---src-pages-user-dashboard-index-jsx": () => import("./../../../src/pages/user/dashboard/index.jsx" /* webpackChunkName: "component---src-pages-user-dashboard-index-jsx" */),
  "component---src-pages-user-dashboard-modal-jsx": () => import("./../../../src/pages/user/dashboard/modal.jsx" /* webpackChunkName: "component---src-pages-user-dashboard-modal-jsx" */),
  "component---src-pages-user-index-jsx": () => import("./../../../src/pages/user/index.jsx" /* webpackChunkName: "component---src-pages-user-index-jsx" */),
  "component---src-pages-user-login-index-jsx": () => import("./../../../src/pages/user/login/index.jsx" /* webpackChunkName: "component---src-pages-user-login-index-jsx" */),
  "component---src-pages-user-new-connection-index-jsx": () => import("./../../../src/pages/user/new-connection/index.jsx" /* webpackChunkName: "component---src-pages-user-new-connection-index-jsx" */),
  "component---src-pages-user-profile-jsx": () => import("./../../../src/pages/user/profile.jsx" /* webpackChunkName: "component---src-pages-user-profile-jsx" */),
  "component---src-pages-user-recovery-email-jsx": () => import("./../../../src/pages/user/recovery-email.jsx" /* webpackChunkName: "component---src-pages-user-recovery-email-jsx" */),
  "component---src-pages-user-register-index-jsx": () => import("./../../../src/pages/user/register/index.jsx" /* webpackChunkName: "component---src-pages-user-register-index-jsx" */),
  "component---src-pages-user-reset-password-jsx": () => import("./../../../src/pages/user/reset-password.jsx" /* webpackChunkName: "component---src-pages-user-reset-password-jsx" */),
  "component---src-pages-why-corsnet-index-jsx": () => import("./../../../src/pages/why-corsnet/index.jsx" /* webpackChunkName: "component---src-pages-why-corsnet-index-jsx" */)
}

