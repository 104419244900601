import { LOGIN, LOGOUT } from "./types";

const useActions = (prevState, dispatch) => {
  const actions = {
    login: (payload) => {
      dispatch({ type: LOGIN, payload });
    },
    logout: (e) => {
      if (e) {
        e.preventDefault();
      }
      if (typeof window !== "undefined") {
        localStorage.setItem("accessToken", "");
        window.location.href = "/";
      }

      dispatch({ type: LOGOUT });
    },
  };
  return actions;
};
export default useActions;
