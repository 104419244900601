import * as React from "react";
import useActions from "./actions";
import reducer, { initialState } from "./reducer";

let AuthContext = React.createContext({
  user: initialState,
  actions: {
    login: () => null,
    logout: () => null,
  },
});

function AuthContextProvider({ children }) {
  let [user, dispatch] = React.useReducer(reducer, initialState);

  const actions = useActions(user, dispatch);

  return (
    <AuthContext.Provider value={{ user, actions }}>
      {children}
    </AuthContext.Provider>
  );
}

let AuthContextConsumer = AuthContext.Consumer;

export { AuthContext, AuthContextProvider, AuthContextConsumer };
